import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeArc,
    placeLogo,
    createSpace
} from '../Utils';
const Boxes = {
  box1: function () {
    JXG.Options.board.minimizeReflow = 'none';
    var brd2 = createSpace(-2, 24, -3, 23);
    brd2.options.image.highlight=false;
    brd2.options.image.opacity=1;
    brd2.options.text.highlight=false;
    placeLogo(brd2);
    makeResponsive(brd2);
    placeTitle(brd2, 'Probability in a Game of Dice', '(Probability of Getting a Given Sum)');
    //brd2.create('text', [11, 20, function(){return 'Rolling a Pair of Dice: Probability of Any Given Sum'}],{display:'internal', highlight:false, anchorX:'middle',fontSize:function(){return 32*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold', fixed:true});
    brd2.create('text', [0, 18, function(){return 'Enter the Desired Sum Total Below (between 2 & 12)'}],{display:'internal', highlight:false, fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
    var sum =brd2.create('input', [0, 16.8, ' ' , ''], {cssStyle:'width:5%',
    fontSize:function(){return 20*brd2.canvasHeight/800}, fixed:true});//
    //brd2.create('input', [0, 6.8, 2, ''], {cssStyle:'width:5%;fontFamily:Oswald;background-color:white;border:0px solid black;border-radius: 3.5px;',fontSize:function(){return 20*brd2.canvasHeight/800}, fixed:true});//
    sum.setLabel('Step # 1: Enter the sum total here')
    sum.label.setAttribute({visible:false, offset:[45, 0], CssStyle:'fontFamily:Oswald', fontSize:function(){return 20*brd2.canvasHeight/800}});
    sum.on('over', function () {this.label.setAttribute({visible:true});});
    sum.on('out', function () {this.label.setAttribute({visible:false});});
    //
    brd2.create('text', [0, 15.5, function(){return 'Tap at the Hand to Roll the Dice!'}],{fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
    var player = brd2.create('image', ['/assets/dices.svg', [2, 10],[4.0,4.0]],{fixed:true, shadow:false, rotate:20});
    player.setLabel('Step # 2: Tap to Roll the Dice!')
    player.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    player.on('over', function () {this.label.setAttribute({visible:true});});
    player.on('out', function () {this.label.setAttribute({visible:false});});
    var i=0;
    var j=0;
    var cnt = 0;
    var tup = [];
    var k =1;
    var m =1;
    var km=1;
    var vis=0;
    var ind = brd2.create('text', [17, 18, function(){return 'Outcomes with Sum Total of ' + sum.Value()}],{visible:()=>vis==1, display:'internal', anchorX:'middle', highlight:false, fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});

    /*var pop = function(){cnt=0;
    for(i=1; i<=6; i++){
    for(j=1; j<=6; j++){
    cnt+=1;
    tup[cnt-1] = [1, j]}
  }};*/
    var run = function(n){cnt=0;
    for(i=1; i<=6; i++){
    for(j=1; j<=6; j++){
    if(i+j ==n){
    cnt+=1;
    tup[cnt-1] = [i, j]}
    }}};
    var pt =function(){
    for(km=1; km<=36; km++){
    brd2.create('text', [19, 15.5-3*(km-1), function(){return '( '+tup[km-1][0]+' + '+tup[km-1][1]+' = '+(tup[km-1][0]+tup[km-1][1]).toFixed(0)+' )'}],{display:'internal', fontSize:function(){return 28*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
    }
    }
    var img=['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n','o', 'p', 'q', 'r', 's', 't'];
    var nme=[];
    var ini = function(){
    brd2.suspendUpdate();
    ind.setAttribute({visible:false});
    txt1.setAttribute({visible:false});
    txt2.setAttribute({visible:false});
    txt3.setAttribute({visible:false});
    txt4.setAttribute({visible:false});
    txt5.setAttribute({visible:false});
    txt6.setAttribute({visible:false});
    txt7.setAttribute({visible:false});
    txt8.setAttribute({visible:false});
    for (m=cnt; m>=1; m--){
    brd2.removeObject(img[2*(m-1)+1],true);
    brd2.removeObject(img[2*(m-1)+2], true);
    brd2.removeObject(nme[m]);
    }
    brd2.unsuspendUpdate();
    }
    var dice = function(){
    ind.setAttribute({visible:true})
    txt1.setAttribute({visible:true});
    txt2.setAttribute({visible:true});
    txt3.setAttribute({visible:true});
    txt4.setAttribute({visible:true});
    txt5.setAttribute({visible:true});
    txt6.setAttribute({visible:true});
    txt7.setAttribute({visible:true});
    txt8.setAttribute({visible:true});
    for (k=1; k<=cnt; k++){
    brd2.create('image', ['/assets/dice-'+tup[k-1][0]+'-red.svg', [13, 17.5-3*k],[2, 2]], {name:img[2*(k-1)+1], fixed:true, highlight:false});
    brd2.create('image', ['/assets/dice-'+tup[k-1][1]+'-green.svg', [17.5,17.5-3*k],[2, 2]],{name:img[2*(k-1)+2], rotate:70, fixed:true, highlight:false});
    nme[k]=brd2.create('text', [19, 15.5-3*(k-1),'( '+tup[k-1][0]+' + '+tup[k-1][1]+' = '+(tup[k-1][0]+tup[k-1][1]).toFixed(0)+' )'],
    {display:'internal', fontSize:function(){return 28*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});

    };
    };
    player.on('down', function(){i=0; j=0; k=1; m=1; tup=[];ini();run(sum.Value()); dice(); vis=1; brd2.update();});
    var txt1 =brd2.create('text', [0, 8, function(){return 'Number of Outcomes with Sum Total of '+sum.Value()}],{highlight:false, visible:false, display:'internal',fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
    var txt2 =brd2.create('text', [5, 7, function(){return  ''+ cnt }],{highlight:false ,anchorX:'middle', display:'internal', color:'blue',visible:false, fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold', fixed:true});

    var txt3 =brd2.create('text', [0, 5.5, function(){return 'Probability of Getting a Sum Total of '+sum.Value()}],{highlight:false ,visible:false, display:'internal',fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
    var txt4 =brd2.create('text', [5, 4.5, function(){return  ''+ cnt + ' in 36'}],{highlight:false , anchorX:'middle',color:'blue',visible:false, display:'internal', fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold', fixed:true});

    var txt5 =brd2.create('text', [0, 3., 'Probability Expressed as a Fraction'],{highlight:false ,display:'internal', visible:false,fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
    var txt6 =brd2.create('text', [5, 2., function(){return  (cnt/36).toFixed(3)}],{highlight:false ,anchorX:'middle',color:'blue',visible:false, display:'internal', fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold', fixed:true});

    var txt7 =brd2.create('text', [0, 0.5, 'Probability Expressed as a Percentage'],{highlight:false ,display:'internal',visible:false, fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
    var txt8 =brd2.create('text', [5, -0.5, function(){return  (100*cnt/36).toFixed(3) + '%'}],{highlight:false,visible:false,anchorX:'middle',color:'blue', display:'internal', fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold', fixed:true});

    brd2.update();
    /////////////////////////////////////////////////////
    let magicGraph = document.querySelector("#jxgbox1");
    let inputFields = magicGraph.querySelectorAll("input");
    var onEachPress = function(event) { ini(); vis=0 }
       inputFields.forEach(function(field) {
       field.addEventListener('input', onEachPress);});
    },
    }
export default Boxes;
